import { Car, Driver } from '~/shared/api'
import {
  debtStatusEnumOptions,
  debtTypeEnumOptions,
} from '~/shared/config/constants'
import { Filter } from '~/shared/ui/AsyncGrid'

export const filters: Filter[] = [
  {
    field: 'startAtFrom',
    label: 'Дата создания от',
    type: 'date',
  },
  {
    field: 'startAtTo',
    label: 'Дата создания по',
    type: 'date',
  },
  {
    field: 'status',
    label: 'Статус',
    type: 'select',
    options: debtStatusEnumOptions,
  },
  {
    field: 'sourceType',
    label: 'Тип',
    type: 'select',
    options: debtTypeEnumOptions,
  },
  {
    field: 'driverExtId',
    label: 'Водитель',
    type: 'autocomplete',
    fetchOptions: Driver.fetchOptionsByExtId,
  },
  {
    field: 'carId',
    label: 'Автомобиль',
    type: 'autocomplete',
    fetchOptions: Car.fetchOptions,
  },
  {
    field: 'isErroneous',
    label: 'Ошибочное списание',
    type: 'select',
    options: [
      { id: 'true', label: 'Да' },
      { id: 'false', label: 'Нет' },
    ],
  },
]
