import { Box, Stack } from '@mui/joy'
import { useQueryClient } from '@tanstack/react-query'
import { useEvent, useGate, useStore } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AuditsModal } from '~/widgets/AuditsModal'
import {
  Status,
  Erroneous,
  ViewDebt,
  debtViewModel,
  CASH_DEPOSIT_GRID_KEY,
} from '~/features/Debt/ViewDebt'
import { CashModal, cashModalModel, debtModel } from '~/entities/Debt'
import { usePermissions } from '~/entities/viewer'
import { DebtSourceTypesEnum, DebtStatusesEnum } from '~/shared/config/enums'
import { formatMoscowDate } from '~/shared/lib/date'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Button } from '~/shared/ui/Buttons'
import { UploadWhiteIcon } from '~/shared/ui/Icons'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'
import { QuickNavigationOptions } from '~/shared/ui/QuickNavigation/QuickNavigation'

export function ViewDebtPage() {
  const { canDebtsUpdate, canAuditsView } = usePermissions()

  const params = useParams<{ debtId: string }>()
  const debtId = params.debtId as string
  useGate(debtViewModel.Gate, { debtId })

  const queryClient = useQueryClient()

  const isDebtLoading = useStore(debtModel.requestFx.pending)
  const debt = useStore(debtViewModel.$debt)
  const deptError = debtModel.useDebtError(debtId)
  const status = debt?.getStatus()
  const sourceType = debt?.getSourceType()
  const isPaid = status === DebtStatusesEnum.PAID

  const inspectionDamages =
    sourceType === DebtSourceTypesEnum.INSPECTION_DAMAGES
  const inspectionViolations =
    sourceType === DebtSourceTypesEnum.INSPECTION_VIOLATIONS

  const title = useMemo(() => {
    const startedAt = debt?.getDate()

    return `Задолженность${
      startedAt ? ` от ${formatMoscowDate(startedAt)}` : ''
    }`
  }, [debt])

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [{ title: 'Задолженности', link: '/debts' }, { title: title || '' }],
    [title],
  )

  const isCashModalOpen = useStore(cashModalModel.$isCashOpen)
  const openCashModal = useEvent(cashModalModel.openCash)
  const closeCashModal = useEvent(cashModalModel.closeCash)
  const isCashSubmitting = useStore(debtViewModel.cashDepositFx.pending)

  const handleCashDepositListUpdate = async () => {
    await queryClient.invalidateQueries({
      queryKey: [CASH_DEPOSIT_GRID_KEY],
    })
  }

  const quickNavigationOptions = [
    { label: 'Осн. информация', anchor: 'main' },
    { label: 'Оплата', anchor: 'payment' },

    inspectionDamages || inspectionViolations
      ? { label: 'Детализация', anchor: 'detailing' }
      : undefined,

    { label: 'Внесение денежных средств', anchor: 'cashList' },
  ].filter(Boolean) as QuickNavigationOptions

  const handleCashSend = async (formValues: cashModalModel.FormValues) => {
    debtViewModel.cashDeposit({ formValues, fn: handleCashDepositListUpdate })
  }

  if (deptError) {
    return <NotFound message='Не удалось загрузить задолженность' />
  }

  return (
    <PageContainer title={title}>
      <Container isHeader>
        <Stack direction='row' alignItems='end' justifyContent='space-between'>
          <Box>
            <Breadcrumbs list={breadcrumbs} lastLoading={isDebtLoading} />

            <Stack direction='row' alignItems='center' spacing={2}>
              <Status />
              {isPaid && <Erroneous />}
            </Stack>

            <PageTitle loading={isDebtLoading}>{title}</PageTitle>
          </Box>
          <Stack direction='row' spacing={2}>
            {canAuditsView && <AuditsModal auditableId={debtId} />}
            {canDebtsUpdate && !isPaid && !isDebtLoading && (
              <Button
                onClick={openCashModal}
                variant='brand'
                startDecorator={<UploadWhiteIcon />}
              >
                Внесение денежных средств
              </Button>
            )}
          </Stack>
        </Stack>
      </Container>

      <ViewDebt debtId={debtId} />

      {!isDebtLoading && <QuickNavigation options={quickNavigationOptions} />}

      <CashModal
        isShow={isCashModalOpen}
        isSubmitting={isCashSubmitting}
        onCancel={closeCashModal}
        onOk={handleCashSend}
      />
    </PageContainer>
  )
}
