import { useStore, useStoreMap } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { debtViewModel } from '~/features/Debt/ViewDebt'
import { debtModel } from '~/entities/Debt'
import { usePermissions } from '~/entities/viewer'
import { FormProvider, Switch } from '~/shared/ui/Form'
import { ErroneousFormValues } from './model'

export function Erroneous() {
  const { canDebtsUpdate } = usePermissions()

  const isChanged = useStore(debtViewModel.isErroneousChangeFx.pending)
  const isDebtLoading = useStore(debtModel.requestFx.pending)

  const defaultValues = useStoreMap(debtViewModel.$debt, (debt) => ({
    isErroneous: debt?.getIsErroneous() || false,
  }))

  const isReadOnly = !canDebtsUpdate || isChanged

  const form = useForm<ErroneousFormValues>({
    defaultValues,
  })

  const { reset, getValues, setValue } = form

  const handleErroneousChange = async () => {
    const formValue = getValues('isErroneous')
    const value = !formValue
    setValue('isErroneous', value)

    try {
      await debtViewModel.isErroneousChangeFx(value)
    } catch (e) {
      reset(defaultValues)
    }
  }

  useEffect(() => {
    reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues])

  return (
    <FormProvider form={form}>
      <Switch
        formControlProps={{ sx: { margin: '2px 0 8px' } }}
        label='Ошибочное списание'
        name='isErroneous'
        skeletonShow={isDebtLoading}
        readOnly={isReadOnly}
        onChange={isReadOnly ? undefined : handleErroneousChange}
      />
    </FormProvider>
  )
}
