import { ToOneRelation } from 'coloquent'
import * as z from 'zod'
import { DebtStatusesEnum, DebtSourceTypesEnum } from '~/shared/config/enums'
import { CSS_DOMAIN, EPS_DOMAIN } from '~/shared/config/env'
import {
  dateSchema,
  priceRequiredScheme,
  uuidOptionSchema,
} from '~/shared/lib/schemas'
import { Car } from './car'
import { ApiModel } from './core'
import { Driver } from './driver'

const relationsSchema = z.object({
  carOption: uuidOptionSchema,
  driverOption: uuidOptionSchema,
})

const attributesSchema = z.object({
  bankclientExported: z.boolean(),
  balance: priceRequiredScheme,
  carPlateNumber: z.string(),
  carVin: z.string(),
  driverFullName: z.string(),
  paid: priceRequiredScheme,
  paymentCost: priceRequiredScheme,
  sourceExtId: z.string().optional(),
  startAt: dateSchema,
  status: z.nativeEnum(DebtStatusesEnum),
  sourceType: z.nativeEnum(DebtSourceTypesEnum),
  total: priceRequiredScheme,
  waitingTimeBeforeStart: z.number(),
  comment: z.string().min(1, 'Обязательное поле'),
  isErroneous: z.boolean().default(false),
})

const schema = z
  .object({
    createdAt: dateSchema,
    updatedAt: dateSchema,
  })
  .merge(attributesSchema)
  .merge(relationsSchema)

export type DebtAttributes = z.infer<typeof schema>

export const cashDepositSchema = z.object({
  comment: z.string().min(3),
  amount: priceRequiredScheme,
  depositingDate: dateSchema,
})
export type CashDepositSchemaType = z.infer<typeof cashDepositSchema>

export type UpdateDebtType = {
  total: DebtAttributes['total']
  paymentCost: DebtAttributes['paymentCost']
  comment: DebtAttributes['comment']
  carId: UniqueId
}

export class Debt extends ApiModel<typeof schema, DebtAttributes> {
  static jsonApiType = 'debts'

  static schema = schema

  getSourceExtId(): string {
    return this.getAttribute('sourceExtId')
  }
  getSourceType(): DebtSourceTypesEnum {
    return this.getAttribute('sourceType')
  }
  getStatus(): DebtStatusesEnum {
    return this.getAttribute('status')
  }
  getDate(): string {
    return this.getAttribute('startAt')
  }
  getIsErroneous(): boolean {
    return this.getAttribute('isErroneous')
  }

  getSourceLink(): string | null {
    const sourceType = this.getSourceType()
    const sourceExtId = this.getSourceExtId()
    if (!sourceExtId) return null

    return [
      DebtSourceTypesEnum.INSPECTION_VIOLATIONS,
      DebtSourceTypesEnum.INSPECTION_DAMAGES,
    ].includes(sourceType)
      ? `${CSS_DOMAIN}inspections/${sourceExtId}`
      : [DebtSourceTypesEnum.EXTERNAL_PENALTY].includes(sourceType)
      ? `${EPS_DOMAIN}penalties/${sourceExtId}`
      : [DebtSourceTypesEnum.EXTERNAL_PENALTY].includes(sourceType)
      ? `${EPS_DOMAIN}penalties/${sourceExtId}`
      : [DebtSourceTypesEnum.TOLL_ROAD].includes(sourceType)
      ? `${EPS_DOMAIN}toll-roads/${sourceExtId}`
      : ''
  }
  getSourceTitle(): string {
    const sourceType = this.getSourceType()
    return [
      DebtSourceTypesEnum.INSPECTION_VIOLATIONS,
      DebtSourceTypesEnum.INSPECTION_DAMAGES,
    ].includes(sourceType)
      ? `Осмотр`
      : [DebtSourceTypesEnum.EXTERNAL_PENALTY].includes(sourceType)
      ? `Штраф`
      : [DebtSourceTypesEnum.PLAN_INSPECTION_DELAY].includes(sourceType)
      ? `Просрочка`
      : [DebtSourceTypesEnum.TOLL_ROAD].includes(sourceType)
      ? 'Проезд'
      : ''
  }
  getTotal(): number {
    return this.getAttribute('total')
  }
  getPaid(): number {
    return this.getAttribute('paid')
  }
  getBalance(): number {
    return this.getAttribute('balance')
  }

  car(): ToOneRelation<Car, this> {
    return this.hasOne(Car)
  }
  getCar(): Car {
    return this.getRelation('car')
  }
  setCar(id: UniqueId) {
    const car = new Car()
    car.setApiId(id)
    this.setRelation('car', car)
  }

  driver(): ToOneRelation<Driver, this> {
    return this.hasOne(Driver)
  }
  getDriver(): Driver {
    return this.getRelation('driver')
  }
  setDriver(id: UniqueId) {
    const driver = new Driver()
    driver.setApiId(id)
    this.setRelation('driver', driver)
  }

  static async cashDeposit(
    formValues: CashDepositSchemaType,
    debtId: UniqueId,
  ) {
    const url = `${this.getJsonApiUrl()}/${debtId}/actions/cash-deposit`
    return this.effectiveHttpClient.post(url, formValues)
  }

  static async changeStatus(
    status: DebtAttributes['status'],
    debtId: UniqueId,
  ) {
    const url = `${this.getJsonApiUrl()}/${debtId}/actions/update-status`
    return this.effectiveHttpClient.post(url, { status })
  }

  static async updateErroneous(isErroneous: boolean, debtId: UniqueId) {
    const url = `${this.getJsonApiUrl()}/${debtId}/actions/update-erroneous`
    return this.effectiveHttpClient.put(url, { isErroneous })
  }

  static async updateDebt(values: UpdateDebtType, debtId: UniqueId) {
    const url = `${this.getJsonApiUrl()}/${debtId}`
    return this.effectiveHttpClient.patch(url, values)
  }
}
